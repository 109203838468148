import { __decorate } from "tslib";
import { compile } from 'path-to-regexp/dist';
import { Component, Vue, Watch } from 'vue-property-decorator';
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.breadcrumbs = [];
    }
    onRouteChange(route) {
        // if you go to the redirect page, do not update the breadcrumbs
        if (route.path.startsWith('/redirect/')) {
            return;
        }
        this.getBreadcrumb();
    }
    created() {
        this.getBreadcrumb();
    }
    getBreadcrumb() {
        const matched = this.$route.matched.filter((item) => item.meta && item.meta.title);
        // this.breadcrumbs = matched.map((item, index) => {
        //   return {
        //     text: item.meta.title,
        //     href: `${item.path}`,
        //     disabled: item.redirect === 'noredirect' || index === matched.length - 1
        //   }
        // })
        // this.breadcrumbs = data
        this.breadcrumbs = matched.filter((item) => {
            return item.meta && item.meta.title;
        });
    }
    isDashboard(route) {
        const name = route && route.name;
        if (!name) {
            return false;
        }
        return name.trim().toLocaleLowerCase() === 'Dashboard'.toLocaleLowerCase();
    }
    pathCompile(path) {
        // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
        const { params } = this.$route;
        const toPath = compile(path);
        return toPath(params);
    }
    handleLink(item) {
        const { redirect, path } = item;
        if (redirect) {
            this.$router.push(redirect).catch(err => {
                // Throw Error "NavigationDuplicated"
                // https://github.com/vuejs/vue-router/issues/2872#issuecomment-522341874
                console.warn(err);
            });
            return;
        }
        this.$router.push(this.pathCompile(path)).catch(err => {
            // Throw Error "NavigationDuplicated"
            // https://github.com/vuejs/vue-router/issues/2872#issuecomment-522341874
            console.warn(err);
        });
    }
};
__decorate([
    Watch('$route')
], default_1.prototype, "onRouteChange", null);
default_1 = __decorate([
    Component({
        name: 'Breadcrumb'
    })
], default_1);
export default default_1;
