import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
// export default {
//   name: 'LanguageSelect',
//   data() {
//     return {
//       language: AppModule.language
//     }
//   },
//   methods: {
//     handleSetLanguage: function(lang: string) {
//       AppModule.SetLanguage(lang)
//       this.$i18n.locale = lang
//       this.$root.$i18n.locale = lang
//       const matched = this.$route.matched.filter((item) => item.meta && item.meta.title)
//       const last = matched[matched.length - 1]
//       const pageName = this.$t(`route.${last.meta.title}`) as string
//       document.title = pageName
//     }
//   }
// }
let default_1 = class extends Vue {
    get language() {
        return AppModule.language;
    }
    handleSetLanguage(lang) {
        AppModule.SetLanguage(lang);
        this.$i18n.locale = lang;
        this.$root.$i18n.locale = lang;
        const matched = this.$route.matched.filter((item) => item.meta && item.meta.title);
        const last = matched[matched.length - 1];
        const pageName = this.$t(`route.${last.meta.title}`);
        document.title = pageName;
    }
};
default_1 = __decorate([
    Component({
        name: 'LanguageSelect'
    })
], default_1);
export default default_1;
