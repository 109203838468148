import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import { UserModule } from '@/store/modules/user';
import Breadcrumb from '@/components/Breadcrumb/index.vue';
import LanguageSelect from '@/components/LanguageSelect/index.vue';
let default_1 = class extends Vue {
    get sidebar() {
        return AppModule.sidebar;
    }
    get avatar() {
        return UserModule.avatar;
    }
    async logout() {
        await UserModule.LogOut();
        this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    }
    toggleSideBar() {
        //console.log('toggleSideBar')
        AppModule.ToggleSideBar(false);
    }
};
default_1 = __decorate([
    Component({
        name: 'Navbar',
        components: {
            Breadcrumb,
            LanguageSelect
        }
    })
], default_1);
export default default_1;
