import { __decorate } from "tslib";
import { getModule, Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import store from '@/store';
let TagsView = class TagsView extends VuexModule {
    constructor() {
        super(...arguments);
        this.visitedViews = [];
        this.cachedViews = [];
    }
    AddView(view) {
        store.dispatch('AddVisitedView', view);
        store.dispatch('AddCachedView', view);
    }
    AddVisitedView(view) {
        return view;
    }
    AddCachedView(view) {
        return view;
    }
    async DelView(view) {
        store.dispatch('DelVisitedView', view);
        store.dispatch('DelCachedView', view);
        return {
            visitedViews: [...this.visitedViews],
            cachedViews: [...this.cachedViews]
        };
    }
    async DelVisitedView(view) {
        store.commit('DEL_VISITED_VIEW', view);
        return [...this.visitedViews];
    }
    async DelCachedView(view) {
        store.commit('DEL_CACHED_VIEW', view);
        return [...this.cachedViews];
    }
    async DelOthersViews(view) {
        store.dispatch('DelOthersVisitedViews', view);
        store.dispatch('DelOthersCachedViews', view);
        return {
            visitedViews: [...this.visitedViews],
            cachedViews: [...this.cachedViews]
        };
    }
    async DelOthersVisitedViews(view) {
        store.commit('DEL_OTHERS_VISITED_VIEWS', view);
        return [...this.visitedViews];
    }
    async DelOthersCachedViews(view) {
        store.commit('DEL_OTHERS_CACHED_VIEWS', view);
        return [...this.cachedViews];
    }
    async DelAllViews(view) {
        store.dispatch('DelAllVisitedViews', view);
        store.dispatch('DelAllCachedViews', view);
        return {
            visitedViews: [...this.visitedViews],
            cachedViews: [...this.cachedViews]
        };
    }
    async DelAllVisitedViews(view) {
        store.commit('DEL_ALL_VISITED_VIEWS', view);
        return [...this.visitedViews];
    }
    async DelAllCachedViews(view) {
        store.commit('DEL_ALL_CACHED_VIEWS', view);
        return [...this.cachedViews];
    }
    UpdateVisitedView(view) {
        return view;
    }
    ADD_VISITED_VIEW(view) {
        if (this.visitedViews.some((v) => v.path === view.path))
            return;
        this.visitedViews.push(Object.assign({}, view, {
            title: view.meta.title || 'no-name'
        }));
    }
    ADD_CACHED_VIEW(view) {
        if (this.cachedViews.includes(view.name))
            return;
        if (!view.meta.noCache) {
            this.cachedViews.push(view.name);
        }
    }
    DEL_VISITED_VIEW(view) {
        for (const [i, v] of this.visitedViews.entries()) {
            if (v.path === view.path) {
                this.visitedViews.splice(i, 1);
                break;
            }
        }
    }
    DEL_CACHED_VIEW(view) {
        for (const i of this.cachedViews) {
            if (i === view.name) {
                const index = this.cachedViews.indexOf(i);
                this.cachedViews.splice(index, 1);
                break;
            }
        }
    }
    DEL_OTHERS_VISITED_VIEWS(view) {
        for (const [i, v] of this.visitedViews.entries()) {
            if (v.path === view.path) {
                this.visitedViews = this.visitedViews.slice(i, i + 1);
                break;
            }
        }
    }
    DEL_OTHERS_CACHED_VIEWS(view) {
        for (const i of this.cachedViews) {
            if (i === view.name) {
                const index = this.cachedViews.indexOf(i);
                this.cachedViews = this.cachedViews.slice(index, index + 1);
                break;
            }
        }
    }
    DEL_ALL_VISITED_VIEWS() {
        this.visitedViews = [];
    }
    DEL_ALL_CACHED_VIEWS() {
        this.cachedViews = [];
    }
    UPDATE_VISITED_VIEW(view) {
        for (let v of this.visitedViews) {
            if (v.path === view.path) {
                v = Object.assign(v, view);
                break;
            }
        }
    }
};
__decorate([
    Action
], TagsView.prototype, "AddView", null);
__decorate([
    Action({ commit: 'ADD_VISITED_VIEW' })
], TagsView.prototype, "AddVisitedView", null);
__decorate([
    Action({ commit: 'ADD_CACHED_VIEW' })
], TagsView.prototype, "AddCachedView", null);
__decorate([
    Action
], TagsView.prototype, "DelView", null);
__decorate([
    Action
], TagsView.prototype, "DelVisitedView", null);
__decorate([
    Action
], TagsView.prototype, "DelCachedView", null);
__decorate([
    Action
], TagsView.prototype, "DelOthersViews", null);
__decorate([
    Action
], TagsView.prototype, "DelOthersVisitedViews", null);
__decorate([
    Action
], TagsView.prototype, "DelOthersCachedViews", null);
__decorate([
    Action
], TagsView.prototype, "DelAllViews", null);
__decorate([
    Action
], TagsView.prototype, "DelAllVisitedViews", null);
__decorate([
    Action
], TagsView.prototype, "DelAllCachedViews", null);
__decorate([
    Action({ commit: 'UPDATE_VISITED_VIEW' })
], TagsView.prototype, "UpdateVisitedView", null);
__decorate([
    Mutation
], TagsView.prototype, "ADD_VISITED_VIEW", null);
__decorate([
    Mutation
], TagsView.prototype, "ADD_CACHED_VIEW", null);
__decorate([
    Mutation
], TagsView.prototype, "DEL_VISITED_VIEW", null);
__decorate([
    Mutation
], TagsView.prototype, "DEL_CACHED_VIEW", null);
__decorate([
    Mutation
], TagsView.prototype, "DEL_OTHERS_VISITED_VIEWS", null);
__decorate([
    Mutation
], TagsView.prototype, "DEL_OTHERS_CACHED_VIEWS", null);
__decorate([
    Mutation
], TagsView.prototype, "DEL_ALL_VISITED_VIEWS", null);
__decorate([
    Mutation
], TagsView.prototype, "DEL_ALL_CACHED_VIEWS", null);
__decorate([
    Mutation
], TagsView.prototype, "UPDATE_VISITED_VIEW", null);
TagsView = __decorate([
    Module({ dynamic: true, store, name: 'tagsView' })
], TagsView);
export const TagsViewModule = getModule(TagsView);
