import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { AppMain, Navbar, Sidebar, TagsView } from './components';
import { AppModule } from '@/store/modules/app';
let default_1 = class extends Vue {
    get sidebar() {
        return AppModule.sidebar;
    }
    get needTagsView() {
        return this.$store.getters.tagsView;
    }
    get classObj() {
        return {
            hideSidebar: !this.sidebar.opened,
            openSidebar: this.sidebar.opened,
            withoutAnimation: this.sidebar.withoutAnimation
        };
    }
};
default_1 = __decorate([
    Component({
        name: 'Layout',
        components: {
            AppMain,
            Navbar,
            Sidebar,
            TagsView
        }
    })
], default_1);
export default default_1;
