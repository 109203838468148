import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let default_1 = class extends Vue {
    get sidebar() {
        return this.$store.getters.sidebar;
    }
    get device() {
        return this.$store.getters.device;
    }
    get showSettings() {
        return this.$store.getters.showSettings;
    }
    get needTagsView() {
        return this.$store.getters.tagsView;
    }
    get fixedHeader() {
        return this.$store.getters.fixedHeader;
    }
    get cachedViews() {
        return '';
    }
    get key() {
        return this.$route.path;
    }
};
default_1 = __decorate([
    Component({
        name: 'AppMain'
    })
], default_1);
export default default_1;
