import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { getSidebarStatus, setSidebarStatus, setLanguage } from '@/utils/cookies';
import { getLocale } from '@/utils/language';
import store from '@/store';
export var DeviceType;
(function (DeviceType) {
    DeviceType[DeviceType["Mobile"] = 0] = "Mobile";
    DeviceType[DeviceType["Desktop"] = 1] = "Desktop";
})(DeviceType || (DeviceType = {}));
let App = class App extends VuexModule {
    constructor() {
        super(...arguments);
        this.sidebar = {
            opened: getSidebarStatus() !== 'closed',
            withoutAnimation: false
        };
        this.device = DeviceType.Desktop;
        this.language = getLocale();
        this.size = 'medium'; //getsize
    }
    TOGGLE_SIDEBAR(withoutAnimation) {
        this.sidebar.opened = !this.sidebar.opened;
        this.sidebar.withoutAnimation = withoutAnimation;
        if (this.sidebar.opened) {
            setSidebarStatus('opened');
        }
        else {
            setSidebarStatus('closed');
        }
    }
    CLOSE_SIDEBAR(withoutAnimation) {
        this.sidebar.opened = false;
        this.sidebar.withoutAnimation = withoutAnimation;
        setSidebarStatus('closed');
    }
    TOGGLE_DEVICE(device) {
        this.device = device;
    }
    SET_LANGUAGE(language) {
        this.language = language;
        setLanguage(this.language);
    }
    SET_SIZE(size) {
        this.size = size;
        //setSize(this.size)
    }
    ToggleSideBar(withoutAnimation) {
        this.TOGGLE_SIDEBAR(withoutAnimation);
    }
    CloseSideBar(withoutAnimation) {
        this.CLOSE_SIDEBAR(withoutAnimation);
    }
    ToggleDevice(device) {
        this.TOGGLE_DEVICE(device);
    }
    SetLanguage(language) {
        this.SET_LANGUAGE(language);
    }
    SetSize(size) {
        this.SET_SIZE(size);
    }
};
__decorate([
    Mutation
], App.prototype, "TOGGLE_SIDEBAR", null);
__decorate([
    Mutation
], App.prototype, "CLOSE_SIDEBAR", null);
__decorate([
    Mutation
], App.prototype, "TOGGLE_DEVICE", null);
__decorate([
    Mutation
], App.prototype, "SET_LANGUAGE", null);
__decorate([
    Mutation
], App.prototype, "SET_SIZE", null);
__decorate([
    Action
], App.prototype, "ToggleSideBar", null);
__decorate([
    Action
], App.prototype, "CloseSideBar", null);
__decorate([
    Action
], App.prototype, "ToggleDevice", null);
__decorate([
    Action
], App.prototype, "SetLanguage", null);
__decorate([
    Action
], App.prototype, "SetSize", null);
App = __decorate([
    Module({ dynamic: true, store, name: 'app' })
], App);
export const AppModule = getModule(App);
