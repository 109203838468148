import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';
let default_1 = class extends Vue {
    constructor() {
        super(...arguments);
        this.goPage = (link) => this.$router.push({ path: link });
        this.isExternal = (path) => /^(https?:|mailto:|tel:)/.test(path);
    }
    created() {
        this.items = [
            { title: 'dashboard', icon: 'mdi-view-dashboard', type: 'link', path: '/dashboard' },
            { title: 'photos', icon: 'mdi-image', type: 'link', path: '/scan' },
            {
                title: 'errorPages',
                icon: 'mdi-alert-circle-outline',
                items: [
                    { type: 'link', path: '/error/401', title: 'page401' },
                    { type: 'link', path: '/error/404', title: 'page404' }
                ]
            },
            { title: 'logout', icon: 'mdi-exit-to-app', type: 'button', func: this.logout }
        ];
    }
    async logout() {
        await UserModule.LogOut();
        this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    }
    triggerMenu(item) {
        if (!item.items)
            item.type === 'link' ? this.goPage(item.path) : item.func.apply(this);
    }
};
default_1 = __decorate([
    Component({
        name: 'Sidebar'
    })
], default_1);
export default default_1;
